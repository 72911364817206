import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon, XCircle as XIcon } from 'react-feather';

import firebase from 'src/lib/firebase';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  }
}));

const Search = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const db = firebase.firestore();
      const collectionName = 'job_applications';
      const applicationRef = db.collection(collectionName);
      const searchValue = value.charAt(0).toUpperCase() + value.slice(1);
      const nameQuery = applicationRef.where('name', '==', searchValue);
      const surnameQuery = applicationRef.where('surname', '==', searchValue);
      const nameMatches = await nameQuery.get();
      const surnameMatches = await surnameQuery.get();
      let m = {};
      const filteredDuplicates = [
        ...nameMatches.docs,
        ...surnameMatches.docs
      ].map(doc => {
        if (m[doc.id] !== true) {
          m[doc.id] = true;
          return doc;
        }
      });
      setResults(filteredDuplicates);

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit" onClick={handleOpen}>
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="textPrimary">
                Search
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={event => setValue(event.target.value)}
                placeholder="Search candidates"
                value={value}
                variant="outlined"
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
            <Box mt={4}>
              {isLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : !results || results.length === 0 ? (
                <Typography variant="body2" color="textPrimary">
                  We did not find any users with the specified name or surname.
                </Typography>
              ) : (
                <>
                  {results.map((result, i) => {
                    if (!result?.data) return <div></div>
                    const worker = result.data();
                    return (
                      <Box key={result.id} mb={2}>
                        <Link
                          variant="h4"
                          color="textPrimary"
                          component={RouterLink}
                          to={`/app/panel/workers/${result.id}`}
                        >
                          {worker.name} {worker.surname} (
                          {new Date(
                            worker.date_of_birth.seconds * 1000
                          ).getFullYear()}
                          )
                        </Link>
                        <Typography variant="body2" color="textPrimary">
                          {worker.phone}
                        </Typography>
                      </Box>
                    );
                  })}
                </>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default Search;
