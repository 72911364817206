import React, { createContext, useEffect, useReducer } from 'react';

import firebase from 'src/lib/firebase';
import _ from 'lodash';

const keyIsAuthenticated = 'kod95-is-authenticated';
const keyUser = 'kod95-user';

const initialAuthState = {
  isAuthenticated:
    JSON.parse(sessionStorage.getItem(keyIsAuthenticated)) ?? false,
  user: JSON.parse(sessionStorage.getItem(keyUser)) ?? null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      sessionStorage.setItem(keyIsAuthenticated, isAuthenticated);
      sessionStorage.setItem(keyUser, JSON.stringify(user));

      return {
        ...state,
        isAuthenticated,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  createUserInDB: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const getUserFromDB = async email => {
    try {
      const collectionName = 'companies';
      const db = firebase.firestore();
      const docRef = db.collection(collectionName).doc(email);
      const doc = await docRef.get();
      if (doc.exists) return doc.data();
      return null;
    } catch (err) {
      return null;
    }
  };

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const createUserInDB = async userObject => {
    const collectionName = 'companies';
    const db = firebase.firestore();

    return db
      .collection(collectionName)
      .doc(userObject.email)
      .set(_.omit(userObject, ['submit', 'password']));
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  const refetchUser = () => {
    if (state.user?.email) {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection('companies')
        .doc(state.user.email)
        .onSnapshot(doc => {
          const changedUser = doc.data();
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                id: state.user.uid,
                avatar: state.user.photoURL,
                email: state.user.email,
                name: state.user.displayName || state.user.email,
                tier: 'Standard',
                ...changedUser
              }
            }
          });
        });

      return unsubscribe;
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        async function fetchUser() {
          const userFromDB = await getUserFromDB(user.email);

          if (userFromDB != null) {
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: true,
                user: {
                  id: user.uid,
                  avatar: user.photoURL,
                  email: user.email,
                  name: user.displayName || user.email,
                  tier: 'Standard',
                  ...userFromDB
                }
              }
            });
          } else {
            // User is not in DB
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
          }
        }

        fetchUser();
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout,
        createUserInDB,
        getUserFromDB,
        refetchUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
