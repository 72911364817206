import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Briefcase as BriefcaseIcon,
  BarChart as BarChartIcon,
  User as UserIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Home as HomeIcon,
  CheckSquare as CheckSquareIcon,
  UserPlus as UserPlusIcon,
  Bell as BellIcon
} from 'react-feather';
import { useTranslation } from 'react-i18next';

import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

function renderNavItems({ items = [], pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getSections = user => {
    const isAdminish = user.isAdmin || user.isModerator || user.isSuperAdmin;
    const panelZaFirme = {
      subheader: 'Panel',
      items: [
        {
          title: t('nav.home'),
          href: '/app/panel',
          icon: HomeIcon
        },
        {
          title: t('nav.workers'),
          href: '/app/panel/workers',
          icon: BriefcaseIcon
        }
      ]
    };

    if (!isAdminish) {
      return [{ ...panelZaFirme }];
    }

    const panelZaAdmine = {
      subheader: 'Admin panel',
      items: [
        {
          title: 'Radnici',
          href: '/app/panel/radnici',
          icon: UsersIcon
        },
        {
          title: 'Export',
          href: '/app/panel/export',
          icon: ShareIcon
        }
      ]
    };

    if (!user.isSuperAdmin) {
      return [
        {
          ...panelZaFirme,
          subheader: isAdminish ? 'Panel za firme (nevazno za vas!)' : 'Panel'
        },
        { ...panelZaAdmine }
      ];
    }

    const panelZaSuperadmine = {
      subheader: 'Super admin panel',
      items: [
        {
          title: 'Pregled',
          href: '/app/panel/finansije/pregled',
          icon: BarChartIcon
        },
        {
          title: 'Unos podataka',
          href: '/app/panel/finansije/unos',
          icon: UserPlusIcon
        },
        {
          title: 'Dnevnik promjena',
          href: '/app/panel/obavjestenja',
          icon: BellIcon
        }
      ]
    };

    return [
      { ...panelZaSuperadmine },
      { ...panelZaAdmine },
      {
        ...panelZaFirme,
        subheader: isAdminish ? 'Panel za firme (nevazno za vas!)' : 'Panel'
      }
    ];
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Avatar alt="User" className={classes.avatar} src={user.avatar} />
          </Box>
          <Box mt={2} textAlign="center">
            <Typography variant="body2" color="textSecondary">
              {user.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {getSections(user).map((section, idx) => (
            <List
              key={`section.subheader-${idx}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
