import * as React from 'react'
import { useTranslation, Trans } from 'react-i18next';
import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';

const lngs = {
  en: { nativeName: '🇬🇧 English' },
  de: { nativeName: '🇩🇪 Deutsch' }
};

const languageOptions = Object.keys(lngs).map(lng => ({
  value: lng,
  label: lngs[lng].nativeName
}))

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? provided.color : 'black',
    'fontFamily': "Roboto"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    'fontFamily': "Roboto"
  })
}

const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  return <Select
    value={languageOptions.find(lng => lng.value === i18n.language)}
    name="languages"
    className="basic-multi-select"
    classNamePrefix="select"
    onChange={(lang) => {
      i18n.changeLanguage(lang.value)
    }}
    options={languageOptions}
    styles={customStyles}
  />
}

export default LanguageSwitch;