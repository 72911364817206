import moment from 'moment';
import mock from 'src/utils/mock';

mock.onGet('/api/customers').reply(() => {
  const customers = [
    {
      licences: '',
      surname: 'Fejzic',
      languages: {
        it: { label: 'Italijanski', value: 'Bez znanja' },
        de: { label: 'Njemački', value: 'Bez znanja' },
        en: { value: 'Bez znanja', label: 'Engleski' },
        fr: { value: 'Bez znanja', label: 'Francuski' },
        ru: { value: 'Bez znanja', label: 'Ruski' },
        sl: { label: 'Slovenački', value: 'Bez znanja' },
        uk: { label: 'Ukrajinski', value: 'Bez znanja' }
      },
      occupation: 'Automehaničar',
      years_of_experience: '0',
      phone: '+38762779152',
      special_requests: '',
      citizenship: ['Bosna i Hercegovina'],
      email: 'hamzafejzic75@gmail.com',
      date_of_birth: '2002-09-11T22:00:00.000Z',
      currently_employed: false,
      immediately_available: true,
      'previous-job-desc':
        'Radio sam kao automehanicar 3 godine, samo sto nisam bio osiguran i nije mi staz isao.',
      education: 'Srednja škola',
      name: 'Hamza',
      countries_of_work: ['Njemačka', 'Austrija'],
      _id: '00k1Z4rrMFbsUCrruz1e',
      __metadata: { segments: ['job_applications', '00k1Z4rrMFbsUCrruz1e'] }
    },
    {
      phone: '+38761433758',
      special_requests: '',
      'previous-job-desc': 'Ciscenje,kuhanje,njega drugog lica',
      years_of_experience: '33',
      education: 'Osnovna škola',
      citizenship: ['Bosna i Hercegovina'],
      email: 'melihazisko9@gmail.com',
      occupation: 'Kuhar',
      surname: 'žiško',
      date_of_birth: '1969-03-10T23:00:00.000Z',
      currently_employed: true,
      name: 'Meiha',
      countries_of_work: ['Austrija', 'Njemačka'],
      licences: '',
      languages: {
        uk: { label: 'Ukrajinski', value: 'Bez znanja' },
        en: { value: 'Bez znanja', label: 'Engleski' },
        ru: { value: 'Bez znanja', label: 'Ruski' },
        fr: { label: 'Francuski', value: 'Bez znanja' },
        sl: { label: 'Slovenački', value: 'Bez znanja' },
        de: { value: 'Osnovne fraze i izrazi (A1)', label: 'Njemački' },
        it: { value: 'Bez znanja', label: 'Italijanski' }
      },
      immediately_available: true,
      _id: '02E4Un5dtrIqGA60FMUx',
      __metadata: { segments: ['job_applications', '02E4Un5dtrIqGA60FMUx'] }
    },
    {
      education: 'Srednja škola',
      countries_of_work: ['Austrija', 'Njemačka'],
      surname: 'Stojković',
      email: 'stashastojkovic@gmail.com',
      licences: '',
      name: 'Nenad',
      citizenship: ['Srbija'],
      immediately_available: false,
      phone: '+381605559812',
      languages: {
        ru: { label: 'Ruski', value: 'Bez znanja' },
        en: { label: 'Engleski', value: 'Samostalno sporazumijevanje (B1)' },
        uk: { value: 'Bez znanja', label: 'Ukrajinski' },
        sl: { label: 'Slovenački', value: 'Bez znanja' },
        de: { label: 'Njemački', value: 'Osnovne fraze i izrazi (A1)' },
        it: { value: 'Bez znanja', label: 'Italijanski' },
        fr: { value: 'Bez znanja', label: 'Francuski' }
      },
      currently_employed: true,
      special_requests: '',
      date_of_birth: '1976-07-06T23:00:00.000Z',
      'previous-job-desc':
        'Montažer nameštaja.Samostalan u izradi i montaži nameštaja od pločastih materijala',
      occupation: 'Stolar',
      years_of_experience: '20',
      _id: '02ItMI7Z8NTQBzGxeBLw',
      __metadata: { segments: ['job_applications', '02ItMI7Z8NTQBzGxeBLw'] }
    },
    {
      surname: 'Boričić',
      countries_of_work: ['Njemačka', 'Slovenija', 'Austrija'],
      special_requests: '',
      email: 'mbmedica2987@gmail.com',
      citizenship: ['Crna Gora'],
      name: 'Maja',
      years_of_experience: '2',
      phone: '+38268532272',
      date_of_birth: '1988-05-06T22:00:00.000Z',
      licences: '',
      currently_employed: true,
      languages: {
        sl: { label: 'Slovenački', value: 'Bez znanja' },
        fr: { value: 'Osnovno sporazumijevanje (A2)', label: 'Francuski' },
        uk: { value: 'Bez znanja', label: 'Ukrajinski' },
        it: { label: 'Italijanski', value: 'Osnovne fraze i izrazi (A1)' },
        ru: { value: 'Bez znanja', label: 'Ruski' },
        en: { value: 'Samostalno sporazumijevanje (B1)', label: 'Engleski' },
        de: { label: 'Njemački', value: 'Bez znanja' }
      },
      occupation: 'Drugo',
      'previous-job-desc':
        'Radila dugomu prodajnom sektoru, direktna prodaja. ',
      immediately_available: false,
      education: 'Visoka škola',
      _id: '02bK29KctVFL2YmmhF2j',
      __metadata: { segments: ['job_applications', '02bK29KctVFL2YmmhF2j'] }
    },
    {
      date_of_birth: '1979-07-24T23:00:00.000Z',
      phone: '+387066518371',
      currently_employed: true,
      citizenship: ['Bosna i Hercegovina', 'Srbija'],
      email: 'draganacokorilo@yahoo.com',
      occupation: 'Drugo',
      countries_of_work: ['Austrija'],
      education: 'Visoka škola',
      years_of_experience: '17',
      languages: {
        en: { value: 'Bez znanja', label: 'Engleski' },
        ru: { value: 'Bez znanja', label: 'Ruski' },
        de: { label: 'Njemački', value: 'Bez znanja' },
        uk: { value: 'Bez znanja', label: 'Ukrajinski' },
        it: { value: 'Bez znanja', label: 'Italijanski' },
        sl: { label: 'Slovenački', value: 'Bez znanja' },
        fr: { value: 'Bez znanja', label: 'Francuski' }
      },
      immediately_available: false,
      licences: '',
      name: 'Dragana',
      surname: 'Čokorilo',
      special_requests: '',
      'previous-job-desc': 'Pedagog u osnovnoj skoli',
      _id: '04F8I8B9OIUy1lTLwnIo',
      __metadata: { segments: ['job_applications', '04F8I8B9OIUy1lTLwnIo'] }
    },
    {
      occupation: 'Stolar',
      countries_of_work: ['Bilo koja država EU'],
      'previous-job-desc':
        'Poslovođa u proizvodnji dječjih igrački od drveta.\n',
      surname: 'Besirovic',
      email: 'Obesirovic84@gmail.com',
      date_of_birth: '1973-01-31T23:00:00.000Z',
      currently_employed: true,
      immediately_available: false,
      citizenship: ['Bosna i Hercegovina'],
      name: 'Osman',
      phone: '+38762974918',
      languages: {
        sl: { value: 'Bez znanja', label: 'Slovenački' },
        it: { value: 'Bez znanja', label: 'Italijanski' },
        de: { label: 'Njemački', value: 'Osnovne fraze i izrazi (A1)' },
        en: { value: 'Bez znanja', label: 'Engleski' },
        fr: { value: 'Bez znanja', label: 'Francuski' },
        ru: { label: 'Ruski', value: 'Bez znanja' },
        uk: { value: 'Bez znanja', label: 'Ukrajinski' }
      },
      special_requests: '',
      licences: '',
      years_of_experience: '22',
      education: 'Srednja škola',
      _id: '04TeU1OKKWp0iC6qC5YQ',
      __metadata: { segments: ['job_applications', '04TeU1OKKWp0iC6qC5YQ'] }
    },
    {
      phone: '+38268863698',
      currently_employed: true,
      languages: {
        sl: { label: 'Slovenački', value: 'Osnovno sporazumijevanje (A2)' },
        fr: { label: 'Francuski', value: 'Bez znanja' },
        uk: { value: 'Bez znanja', label: 'Ukrajinski' },
        de: { label: 'Njemački', value: 'Bez znanja' },
        ru: { label: 'Ruski', value: 'Osnovno sporazumijevanje (A2)' },
        it: { value: 'Bez znanja', label: 'Italijanski' },
        en: { label: 'Engleski', value: 'Osnovno sporazumijevanje (A2)' }
      },
      name: 'Otaš',
      citizenship: ['Crna Gora'],
      date_of_birth: '1988-12-31T23:00:00.000Z',
      licences:
        'Sertifikat za kuvara\nKurs osnovni nivo engleskog\nVozacka dozvola B i C kategorije',
      special_requests: '',
      'previous-job-desc':
        'Kuvar hotel Rezidens Bidva\nGlavni kuvar hotel Splendid Budva\nŠef smjene hotel Cerkno Slovenija\nŠef kuhinje Ski hotel Žabljak',
      email: 'otassrdanovic@gmail.com',
      immediately_available: true,
      education: 'Srednja stručna škola',
      countries_of_work: ['Slovenija'],
      surname: 'Srdanović',
      years_of_experience: '12',
      occupation: 'Kuhar',
      _id: '04kYaTDES9bxzWPMHCXF',
      __metadata: { segments: ['job_applications', '04kYaTDES9bxzWPMHCXF'] }
    },
    {
      languages: {
        it: { value: 'Bez znanja', label: 'Italijanski' },
        fr: { label: 'Francuski', value: 'Bez znanja' },
        ru: { label: 'Ruski', value: 'Bez znanja' },
        en: { value: 'Bez znanja', label: 'Engleski' },
        de: { label: 'Njemački', value: 'Osnovne fraze i izrazi (A1)' },
        sl: { label: 'Slovenački', value: 'Bez znanja' },
        uk: { value: 'Bez znanja', label: 'Ukrajinski' }
      },
      occupation: 'Bravar',
      immediately_available: true,
      name: 'Nedim',
      years_of_experience: '1',
      licences: '',
      surname: 'Eminović',
      currently_employed: false,
      phone: '+38761668853',
      education: 'Srednja škola',
      special_requests: '',
      email: 'nedimeminovic1977@gmail.com',
      citizenship: ['Bosna i Hercegovina'],
      countries_of_work: ['Bilo koja država EU'],
      'previous-job-desc':
        'Uglavnom sam radio na obezbjeđenju ljudi i imovine u zaštitarskim agencijama za sta posjedujem i certifikat. Imam položen vozački C kategorije i nedavno sam položio i kod 95. Trenutno sam bez posla i zanima me posao vozača ili bilo koji drugi posao koji je ozbiljan i solidno plaćen.',
      date_of_birth: '1977-12-27T23:00:00.000Z',
      _id: '04yqAqniT9a3JiGNjZ16',
      __metadata: { segments: ['job_applications', '04yqAqniT9a3JiGNjZ16'] }
    },
    {
      countries_of_work: ['Bilo koja država EU'],
      education: 'Srednja škola',
      years_of_experience: '20',
      phone: '+381603050166',
      licences: '',
      currently_employed: false,
      surname: 'Kotarac',
      email: 'Kok1105va@gmail.com',
      occupation: 'Elektromonter',
      languages: {
        de: { value: 'Bez znanja', label: 'Njemački' },
        it: { value: 'Bez znanja', label: 'Italijanski' },
        fr: { label: 'Francuski', value: 'Osnovno sporazumijevanje (A2)' },
        sl: { label: 'Slovenački', value: 'Bez znanja' },
        en: { label: 'Engleski', value: 'Osnovno sporazumijevanje (A2)' },
        uk: { label: 'Ukrajinski', value: 'Bez znanja' },
        ru: { value: 'Bez znanja', label: 'Ruski' }
      },
      'previous-job-desc': '20 godina rada u Telekomu-Srbia. ',
      immediately_available: true,
      special_requests: 'Nemam nikakvih uslova ',
      name: 'Milan',
      date_of_birth: '1968-11-16T23:00:00.000Z',
      citizenship: ['Srbija'],
      _id: '05i95RQUQwOAFxEjmgc7',
      __metadata: { segments: ['job_applications', '05i95RQUQwOAFxEjmgc7'] }
    },
    {
      licences: 'Humanitarni deminer',
      date_of_birth: '1967-11-13T23:00:00.000Z',
      special_requests: 'Nema',
      immediately_available: true,
      years_of_experience: '25',
      occupation: 'Drugo',
      name: 'Nermin',
      'previous-job-desc': 'Deminiranje',
      phone: '+38761558819',
      countries_of_work: ['Bilo koja država EU'],
      currently_employed: true,
      languages: {
        uk: { value: 'Bez znanja', label: 'Ukrajinski' },
        it: { value: 'Osnovne fraze i izrazi (A1)', label: 'Italijanski' },
        de: { label: 'Njemački', value: 'Osnovne fraze i izrazi (A1)' },
        ru: { label: 'Ruski', value: 'Bez znanja' },
        fr: { label: 'Francuski', value: 'Bez znanja' },
        sl: { value: 'Bez znanja', label: 'Slovenački' },
        en: { value: 'Samostalno sporazumijevanje (B1)', label: 'Engleski' }
      },
      education: 'Srednja stručna škola',
      email: 'nerminmuminovich51@gmail.com',
      citizenship: ['Bosna i Hercegovina'],
      surname: 'Muminovic',
      _id: '06IAl6GRLdZRwIjytp5n',
      __metadata: { segments: ['job_applications', '06IAl6GRLdZRwIjytp5n'] }
    }
  ];

  return [200, { customers }];
});

mock.onGet('/api/customers/1').reply(() => {
  const customer = {
    id: 'ZpefXhxet4JqNgEADhF7',
    date_of_birth: '1979-07-24T23:00:00.000Z',
    phone: '+387066518371',
    currently_employed: true,
    citizenship: ['Bosna i Hercegovina', 'Srbija'],
    email: 'draganacokorilo@yahoo.com',
    occupation: 'Drugo',
    countries_of_work: ['Austrija'],
    education: 'Visoka škola',
    years_of_experience: '17',
    languages: {
      en: { value: 'Bez znanja', label: 'Engleski' },
      ru: { value: 'Bez znanja', label: 'Ruski' },
      de: { label: 'Njemački', value: 'Bez znanja' },
      uk: { value: 'Bez znanja', label: 'Ukrajinski' },
      it: { value: 'Bez znanja', label: 'Italijanski' },
      sl: { label: 'Slovenački', value: 'Bez znanja' },
      fr: { value: 'Bez znanja', label: 'Francuski' }
    },
    immediately_available: false,
    licences: '',
    name: 'Dragana',
    surname: 'Čokorilo',
    special_requests: '',
    'previous-job-desc': 'Pedagog u osnovnoj skoli',
    _id: '04F8I8B9OIUy1lTLwnIo',
    __metadata: { segments: ['job_applications', '04F8I8B9OIUy1lTLwnIo'] }
  };

  return [200, { customer }];
});

mock.onGet('/api/customers/1/emails').reply(() => {
  const emails = [
    {
      id: '5ece2ce3613486d95ffaea58',
      createdAt: moment()
        .subtract(3, 'days')
        .subtract(5, 'hours')
        .subtract(34, 'minutes')
        .toDate()
        .getTime(),
      description: 'Order confirmation'
    },
    {
      id: '5ece2ce8cebf7ad1d100c0cd',
      createdAt: moment()
        .subtract(4, 'days')
        .subtract(11, 'hours')
        .subtract(49, 'minutes')
        .toDate()
        .getTime(),
      description: 'Order confirmation'
    }
  ];

  return [200, { emails }];
});

mock.onGet('/api/customers/1/invoices').reply(() => {
  const invoices = [
    {
      id: '5ece2cef3e562cbd61996259',
      currency: '$',
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      issueDate: moment()
        .toDate()
        .getTime(),
      paymentMethod: 'Credit Card',
      status: 'paid',
      value: 5.25
    },
    {
      id: '5ece2cf461b9484866f2968c',
      currency: '$',
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      issueDate: moment()
        .toDate()
        .getTime(),
      paymentMethod: 'Credit Card',
      status: 'paid',
      value: 5.25
    }
  ];

  return [200, { invoices }];
});

mock.onGet('/api/customers/1/logs').reply(() => {
  const logs = [
    {
      id: '5ece2cfeb6e2ac847bba11ce',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
        .toDate()
        .getTime(),
      description: 'Purchase',
      ip: '84.234.243.42',
      method: 'POST',
      route: '/api/purchase',
      status: 200
    },
    {
      id: '5ece2d02510484b2952e1e05',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
        .toDate()
        .getTime(),
      description: 'Purchase',
      ip: '84.234.243.42',
      method: 'POST',
      route: '/api/purchase',
      status: 522
    },
    {
      id: '5ece2d08e2748e4e9788901a',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(8, 'minutes')
        .subtract(23, 'seconds')
        .toDate()
        .getTime(),
      description: 'Cart remove',
      ip: '84.234.243.42',
      method: 'DELETE',
      route: '/api/products/d65654e/remove',
      status: 200
    },
    {
      id: '5ece2d0c47214e342c2d7f28',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(20, 'minutes')
        .subtract(54, 'seconds')
        .toDate()
        .getTime(),
      description: 'Cart add',
      ip: '84.234.243.42',
      method: 'GET',
      route: '/api/products/d65654e/add',
      status: 200
    },
    {
      id: '5ece2d11e4060a97b2b57623',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(34, 'minutes')
        .subtract(16, 'seconds')
        .toDate()
        .getTime(),
      description: 'Cart add',
      ip: '84.234.243.42',
      method: 'GET',
      route: '/api/products/c85727f/add',
      status: 200
    },
    {
      id: '5ece2d16cf6d53d8e33656af',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(54, 'minutes')
        .subtract(30, 'seconds')
        .toDate()
        .getTime(),
      description: 'View product',
      ip: '84.234.243.42',
      method: 'GET',
      route: '/api/products/c85727f',
      status: 200
    },
    {
      id: '5ece2d1b2ec5071be9286a96',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(56, 'minutes')
        .subtract(40, 'seconds')
        .toDate()
        .getTime(),
      description: 'Get products',
      ip: '84.234.243.42',
      method: 'GET',
      route: '/api/products',
      status: 200
    },
    {
      id: '5ece2d22e68d5498917e47bc',
      createdAt: moment()
        .subtract(2, 'days')
        .subtract(57, 'minutes')
        .subtract(5, 'seconds')
        .toDate()
        .getTime(),
      description: 'Login',
      ip: '84.234.243.42',
      method: 'POST',
      route: '/api/login',
      status: 200
    }
  ];

  return [200, { logs }];
});
